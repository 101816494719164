<!--
 * @Author: 智客云网络科技
 * @Date: 2021-03-29 19:15:59 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2021-12-08 21:15:09
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Frame\500.vue
-->
<style scoped>
.content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  cursor: pointer;
  height: 500px;
}
.tips-box {
  text-align: center;
  margin-top: 0px;
}
.xian {
  width: 500px;
  height: 2px;
  margin: 40px auto 30px auto;
  background-color: #515151;
}
.error-icon {
  width: 200px;
  height: 200px;
}
.error-tips {
  font-size: 20px;
  font-weight: 700;
  color: #515151;
}
</style>

<template>
  <div class="content-box">
    <div class="tips-box">
      <p>
        <!-- <img class="error-icon"
             src="@/assets/img/kulian.png"> -->
      </p>
      <div class="xian"></div>
      <p class="error-tips">
        <span>500 - 服务器好像出了点问题</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
